.partnerlogo {
    background: #000;
}

.partnerlogo .container {
    margin-bottom: 0;
    // padding-bottom: 100px;
}

.partnerlogo-inner .section-header h3 {
    font-size: 48px !important;
    line-height: 93.5% !important;
    font-weight: 600 !important;
}

.partnerlogo .logos {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // flex-wrap: wrap;
    align-items: center;
    @media screen and (max-width: 850px) {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}
.partnerlogo .logos img{
    // height: 100%;
    @media screen and (max-width: 850px) {
        margin-bottom: 20px;
    }
}
.partnerlogo .logos img:nth-child(1){
    // max-height: 25vh;
    max-width: 275px;
}
.partnerlogo .logos img:nth-child(2){
    // max-height: 25vh;
    max-width: 170px;
}
.partnerlogo .logos img:nth-child(3){
    // max-height: 25vh;
    max-width: 245px;
}
.partnerlogo .logos img:nth-child(4){
    // max-height: 25vh;
    max-width: 100px;
    width: 100%;
}

@media screen and (min-width: 992px){
    .partnerlogo {
        background: #130D1B;
    }
}