.myprofile {
  padding-top: 120px !important;
  background: linear-gradient(0deg, #000000 3.67%, rgba(0, 0, 0, 0) 54.42%);
  background-color: rgba(255, 128, 219, 0.13);
  .form-input {
    border-color: transparent;
    border-radius: 2px;
  }
}

.myprofile .account-section {
  display: flex;
  align-items: center;
  >div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .profile-image {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @media only screen and (max-width: 786px) {
        justify-content: center;
      }
      >div:nth-child(1) {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          border-radius: 50%;
          border: 2px solid #56564B;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 223px;
          height: 223px;
          padding: 20px;
        }
        span{
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          span {
            background-image: url('/assets/images/blue-arrow.svg');
            background-repeat: no-repeat;
            position: absolute;
            width: 18px;
            height: 11px;
            margin-top: 5px;
            margin-left: 10px;
          }
        }
      }
      >div:nth-child(2) {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        line-height: 1.5;
        >span:nth-child(1){
          font-size: 34px;
        }
        >span:nth-child(2){
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
}

.myprofile-inner .section-header {
  padding-bottom: 40px;
}

.myprofile-inner .section-header h3 {
  margin-top: -90px;
  font-size: 40px;
  line-height: 44.88px;
}

.myprofile-inner .section-header p {
  font-size: 18px;
  line-height: 24px;
}

@include media(">medium") {
  .myprofile {
    .form-input {
      min-width: 280px;
    }
  }
}

.myprofile-inner .integrate-item-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.myprofile-inner .integrate-item-list .integrate-item {
  background: linear-gradient(
      133.83deg,
      rgba(255, 128, 219, 0.13) 1.7%,
      rgba(82, 106, 192, 0.13) 53.92%,
      rgba(82, 229, 239, 0.13) 109.64%
    ),
    rgba(255, 255, 255, 0.05);
  box-shadow: 9px 19px 33px rgba(0, 0, 0, 0.65), 3px 3px 1px rgba(0, 0, 0, 0.5),
    inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.myprofile-inner .integrate-item-list .integrate-item h7 {
  font-size: 24px;
  line-height: 30.12px;
}

.myprofile-inner .integrate-item-list .integrate-item p {
  font-size: 16px;
  line-height: 20.08px;
}

.myprofile-inner .integrate-item-list .integrate-item img {
  width: 191px;
  height: 295px;
  margin: 25px 47px 65px 47px;
}

.myprofile-inner .integrate-item-list .integrate-item .issue-title{
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(
    131.47deg,
    rgba(3, 3, 3, 0.13) 1.2%,
    rgba(21, 28, 52, 0.13) 49.77%,
    rgba(95, 194, 201, 0.13) 101.59%),
    rgba(255, 255, 255, 0.05);
  mix-blend-mode: normal;
  box-shadow: 9px 5px 33px rgba(0, 0, 0, 0.65), 3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(15px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 85px;
}

.myprofile-inner .integrate-item-list .read-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  gap: 10px;
  background-color: #308DE2;
  border: 2px solid #1E6AAF;
  border-radius: 7px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  width: 217px;
  height: 53px;
  margin: 30px auto;
}
