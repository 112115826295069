/* The container <div> - needed to position the dropdown content */
.dropbtn {
  
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-content{
  display: block !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  padding: 8px 16px !important;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  text-decoration: underline;
}
