.swiper-button-prev {
  width: 124px;
  height: 124px;
  background-image: url('../../../images/carousel-arrow.png');
  background-size: contain;
  transform:  rotate(180deg);
  left: -70px;

  &::after {
    content: none;
  }

  @media (max-width: 768px) {
    top: 400px;
  }
}

.swiper-button-next {
  width: 124px;
  height: 124px;
  background-image: url('../../../images/carousel-arrow.png');
  background-size: contain;
  right: -70px;

  &::after {
    content: none;
  }

  @media (max-width: 768px) {
    top: 400px;
  }
}

.hero {
  // background: radial-gradient(126.75% 88.22% at 0% 4.47%, #CBCDF3 0%, #7870A6 30.97%, #32285A 49.23%, #1D183C 66.77%, #181431 87.87%, rgba(20, 20, 49, 0.68) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  mix-blend-mode: normal;
  transition: all 1s;

  background-image: url("../../../images/sections/hero/BGHero-CostracaMobile.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media screen and (min-width: 475px) {
  background-image: url("../../../images/sections/hero/BGHero-Costraca.png");
  }
}

.hero .hero-top {
  padding-top: 0px;
  background-color: #111219B2;
}

.hero .hero-top p {
  max-width: 500px;
}

.hero-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.left-button-group {
  justify-content: left !important;
}

.hero-figure {
  flex: 50%;
  margin-top: 0px;
  padding-right: 180px;
}

.illustration-element-01 {
  margin-top: 4rem;
}

.hero-figure {
  padding: 0 0 0 1rem;
}

.hero-figure img {
  width: 80%;
  height: auto;
  //box-shadow: inset -80px -46px 184px rgba(7, 4, 22, 0.2);
  // filter: drop-shadow(-70px 154px 155px rgba(0, 0, 0, 0.61));
}

.hero-content {
  flex: 40%;
  @media all and (max-width: 768px) {
    width: 90%;
  }

}

.hero-content h3 {
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 89.5%;
}

.hero-content .buttons {

  > a:nth-child(2) {
    margin-left: 10px;
    background-color: transparent;
    border: 1px solid #b890f4;
  }
}

.hero-content h1 {
  font-size: 96px;
  font-style: normal;
  font-weight: 600;
  line-height: 89.5%;
}

.hero-content p {
  font-size: 20px;
  line-height: 31px;
}

.hero-bottom {
  background: linear-gradient(180deg, rgba(17, 18, 25, 0.69) 0%, rgba(0, 0, 0, 1) 15%);
  padding: 1.5rem 1rem;
}

.hero-bottom .hero-inner {
  padding-bottom: 30px;
  max-width: 388px;
  margin: auto;

  @media (min-width: 768px) {
    max-width: unset;
  }
}

.hero-bottom .hero-inner h4 {
  font-size: 45px;
  line-height: 125.5%;
}

.hero-bottom .hero-inner p {
  font-weight: 300;
  font-size: 26px;
  line-height: 125.5%;

  color: #d9d9d9;
}

.hero-bottom .hero-inner .hero-figure {
  padding-left: 0;
  width: fit-content;
  flex: 40% 1;

  img {
    margin-right: 0;
    margin-left: 0;
  }
}

.hero-bottom .hero-inner .input-group {
  display: flex;
}

.hero-bottom .hero-inner .input-group form {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}

.hero-bottom .hero-inner .input-group input {
  width: 150%;
  background-color: transparent;
  border: 1px solid #b890f4;
  border-width: 0px 0px 1px 0px;
  color: #b890f4;
}

.hero-bottom .hero-inner .input-group input::placeholder {
  color: #b890f4;
}

.hero-bottom .hero-inner .input-group button {
  background-color: transparent;
  border: 1px solid #b890f4;
  width: 100%;
  margin: 1rem 0 0 0;
  font-weight: 400;
}

.hero-bottom .hero-inner img {
  min-width: 240px;
  max-width: 685px;
}

  /** Comig book **/
.comic-book-cover-desktop{
  display: none;
}

.comic-book-cover-mobile {
  display: initial;
}

.comic-book-cover-mobile-container{
  background-image: url("../../../images/sections/hero/Light_rays1.png");
}

.hero-top p {
  padding-left: 1rem;
  padding-right: 1rem;
}

.launch-drop {
  text-align: center;
  margin-left: auto;
  width: fit-content;

  @media screen and (max-width: 768px) {
    padding-right: 15px;
    > div {
      flex-wrap: wrap;
    > a.button{
      width: 100%;
      margin-left: 0px !important;
      margin-top: 15px;
    }
  }
}
}


@media screen and (min-width: 992px) {

  .hero-top p {
    padding-left: 0rem;
    padding-right: 1rem;
  }
  .hero-figure {
    flex: 50%;
    margin-top: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .hero-bottom {
    background: linear-gradient(180deg, rgba(17, 18, 25, 0.69) 0%, rgba(0, 0, 0, 0.8) 100%);
    padding-left: 50px;
    padding-right: 50px;
  }

  .hero-bottom .hero-inner {
    max-width: 1076px;
    margin: auto;

    p {
      margin-bottom: 2rem;
    }
  }

  .hero-content {
    flex: 40%;
  }

  .hero-bottom .hero-inner .input-group form {
    flex-wrap: nowrap;
    // padding-right: 20%;
  }

  .hero-bottom .hero-inner .input-group button {
    background-color: transparent;
    border: 1px solid #b890f4;
    margin: 0 0 0 5px;
    font-weight: 400;
  }

  .hero-bottom .hero-inner .input-group input {
    min-width: 300px;
    background-color: transparent;
    border: 1px solid #b890f4;
    border-width: 0px 0px 1px 0px;
    color: #b890f4;
  }

  /** Comig book **/
  .comic-book-cover-desktop{
    display: initial;
  }

  .comic-book-cover-mobile {
    display: none;
  }
}

.mint-popup {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1000px;
  background-color: #302F43;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px 40px 10px 7%;
  z-index: 52;
  @media all and (max-width: 768px) {
    overflow-y: auto;
    top: 10% !important;
    height: 600px;
  }

  .close-button {
    cursor: pointer;
    display: flex;
    align-self: end;
    width: 40px;
    height: 40px;
    min-height: 40px;
    background: url('/assets/images/close.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .content {
    padding-right: 2rem;
    margin-top: 50px;
    letter-spacing: 1px;
    .title {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1;
      @media all and (min-width: 768px) {
        font-size: 2rem;
      }
    }
    .text-content {
      padding-right: 1rem;
      max-width: 750px;
      @media all and (min-width: 768px) {
        padding-right: 8rem;
        width: 100%;
      }
    }
    .description {
      color: #FFFFFF;
      font-size: .8rem;
      line-height: .8rem;
      font-weight: normal;
      padding-top: .5rem;
      @media all and (min-width: 768px) {
        font-size: 1rem;
        line-height: 1rem;
      }
    }

    form {
      margin-top: 20px;
      width: 10rem;
      @media all and (max-width: 768px) {
        width: 100%;
      }
      input,select {
        min-width: 300px;
        background-color: transparent;
        border: 1px solid #b890f4;
        border-width: 0px 0px 1px 0px;
        color: #b890f4;
        padding-left: 0px;
        @media all and (max-width: 768px) {
          min-width: unset;
          width: 100%;
        }
      }
      input::placeholder {
        color: #b890f4;
      }
      span {
        font-size: 14px;
      }
      button{
        background-color: transparent;
        border: 1px solid #b890f4;
        
        font-weight: 400;
        width: 115px;
        margin-top: 20px;
      }
      .grecaptcha-badge {
        position: relative !important;
        margin-top: 40px !important;
        margin-bottom: 20px !important;
        bottom: 0 !important;
        left: 0;
        visibility: visible !important;
      }

      .typeSelect{
        background-color: transparent;
        color: #b890f4;
        width: 100%;
        border: 0;
        margin-top: 15px;
        margin-left: 0;
        padding-left: 0;
      }
    }

    .bottom-content {
      display: flex;
      padding: 1rem;
      flex-wrap: wrap-reverse;
      .left-content {
        flex: 50%;
      }
      .right-content {
        flex: 50%;
        padding-left: 3rem;
        padding-bottom: 1rem;
        img {
          width: 75%;
          float:right;
          margin-top: 20px;
          @media all and (max-width: 768px) {
            width: 90%;
            float: none;
          }
        }
        @media all and (max-width: 768px) {
          padding-left: 1rem;
        }
      }
      .create-wallet {
        font-size: 11px;
        width: 100%;
        display: block;
        min-width: 300px;
        a {
          font-style: italic;
          color: #6b6dff;
          &:hover {
            color: #308DE2;
          }
          
        }
      }
    }
    @media all and (max-width: 768px) {
      width: 100%;
      margin-top: 10px;
      padding-right: 0rem;
    }

  }

  @media all and (max-width: 768px) {
    width: 90%;
    top: 15%;
    padding: 20px;
  }

}

.button-coming-soon {
  span {
    &:nth-child(2){
      display: none;
    }
  }
  &:hover {
    span {
      &:nth-child(2){
        display: block;
      }
      &:nth-child(1){
        display: none;
      }
    }
  }
}

.hero-carousel {
  .comic-dropdown {
    margin: 36px auto 54px;
    width: 100%;

    h3 {
      width: fit-content;
      font-size: 32px;
      font-weight: 500;
      padding-bottom: 15px;
      border-bottom: 2px solid #8A8A8A;
      text-transform: uppercase;
      margin: 0 auto;
      line-height: 27px;
    }
  }

  .hero-carousel-item {
    padding: 0 7px;

    .hero-carousel-item-inner {
    max-width: 360px;
      display: flex;
      flex-wrap: wrap;
      height: 702px;
      margin: auto;
      padding: 35px 12px 22px 15px;
      background: linear-gradient(118.92deg, rgba(237, 90, 143, 0.1) 1.07%, rgba(82, 126, 239, 0.1) 99.61%), rgba(17, 18, 25, 0.7);
      box-shadow: 9px 7px 60px rgba(12, 21, 22, 0.44), 9px 19px 33px rgba(0, 0, 0, 0.5), 3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
      border-radius: 15px;
      text-align: left;
      position: relative;
      overflow: hidden;

      .title {
        h3 {
          font-size: 26px;
          font-weight: 600;
          line-height: 22px;
          margin: 0;
        }

        h1 {
          font-size: 46px;
          font-weight: 600;
          line-height: 42px;
          margin: 0;
        }
      }

      .copy {
        margin-top: auto;
        z-index: 1;

        p {
          font-size: 18px;
          font-weight: 400;
          line-height: 31px;
          padding: 0;
          margin: 22px 0;
        }

        span {
          font-weight: 300;
          color: #308DE2;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 268px;
          height: 59px;
          margin: 0 auto;
          border-radius: 7px;
          font-size: 16px;
          font-weight: 600;
          line-height: 23px;
          background-color: #308DE2;
          color: #FFFFFF;
          border: none;
          text-transform: uppercase;
          cursor: pointer;
        }
      }

      .comic-book-cover {
        z-index: 1;
        max-width: 85%;
        position: absolute;
        top: 132px;
        right: 20px;
      }

      .lightray {
        background-image: url("../../../images/sections/hero/Light_rays1.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        opacity: 0.6;
        position: absolute;
        top: 75px;
        right: -220px;
        transform: rotate(35deg);
        width: 100%;
        height: 100%;
        object-fit: contain;
        z-index: 0;
      }
    }

    .mint-now {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 326px;
      height: 326px;
      padding: 25px 28px 32px;
      margin: 34px auto 0;
      background: linear-gradient(118.92deg, rgba(237, 90, 143, 0.6) 1.07%, rgba(82, 126, 239, 0.6) 99.61%), rgba(0, 0, 0, 0.65);
      box-shadow: 9px 7px 60px #0C1516, 3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
      border-radius: 15px;
      text-transform: uppercase;
      text-align: left;

      h3 {
        margin: 0;
      }

      button {
        padding: 15px 0;
        background: transparent;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: 7px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
      }
    }
  }
}

@media (min-width: 768px) {
  .hero-carousel {
    .comic-dropdown {
      margin-top: 116px;
    }

    .hero-carousel-item {
      .hero-carousel-item-inner {
        max-width: 1070px;
        height: 417px;
        padding-left: 52px;
        align-items: center;

        .title {
          height: fit-content;
          width: 60%;

          h3 {
            font-size: 38px;
            line-height: 32px;
          }

          h1 {
            font-size: 58px;
            line-height: 50px;
          }
        }

        .comic-book-cover {
          max-width: unset;
          width: 32%;
          margin: 0;
          right: 30px;
          top: 50px;
        }

        .copy {
          width: 52%;
          margin-top: -75px;
          
          a {
            margin: 0;
          }
        }

        .lightray {
          background-size: 440px;
          top: 0;
          right: -300px;
        }
      }

      .mint-now {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: unset;
        max-width: 1076px;
        height: 134px;

        h3 {
          font-size: 24px;
        }
        button {
          width: 259px;
        }
      }
    }
  }
}

@media (min-width: 1080px) {
  .hero-carousel {
    .hero-carousel-item {
      max-width: unset;
      width: 1076px;

      .hero-carousel-item-inner {
        padding-left: 100px;

        .comic-book-cover {
          top: 40px;
          right: 40px;
        }

        .copy {
          p {
            padding-right: 69px;
          }
        }
      }

      .mint-now {
        width: 1076px;
        padding: 0;

        h3 {
          font-size: 32px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .subscribe-input {
    background-image: url("../../../images/sections/hero/email-vector.png");
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 42px;
  }
}
