.images-menu {
    position: absolute;
    top: 480px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #000000;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 333px;
    height: 388px;
    padding: 20px;
    z-index: 1;
    overflow-y: auto;
    > div {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 15px 15px;
        gap: 18px;
        width: 100;
        background: linear-gradient(118.92deg, rgba(237, 90, 143, 0.13) 1.07%, rgba(82, 126, 239, 0.13) 99.61%), rgba(29, 29, 34, 0.55);
        box-shadow: 9px 19px 33px rgba(0, 0, 0, 0.65), 3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
        border-radius: 10px;
        margin: 10px 0;
        >div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
                width: 56px;
                height: 56px;
                border-radius: 50%;
                border: 2px solid #56564B;
                margin-right: 10px;
            }
        }
        
        > div {
            font-size: 16px;
        }
    }
}