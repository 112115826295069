.universe .hero-content {
  padding-left: 10%;
}

.universe .hero-content h1 {
  font-size: 48px;
  line-height: 93.5%;
}

.universe .hero-content p.subgraph {
  font-size: 16px;
  line-height: 24px;
  color: #eef3fa;
}

#universe-armorite {
  display: initial;
  position: absolute;
  top: -100px;
  left: -170px;
}

@media only screen and (max-width: 786px) {
  .universe .hero-content {
    padding: 0px 10% !important;
  }

  #universe-armorite {
    display: none;
  }
}