.site-footer {
  background: #261937;
}

.site-footer .footer-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-footer .footer-left .footer-copyright {
  font-size: 1rem;
  padding-left: 20px;
  text-align: left;
}

.footer-nav a {
  color: white;
}
