.container {
    margin: 30px auto;

    .controls {
        display: flex;
        justify-content: space-between;
        width: 99%;
        margin-bottom: 20px;

        @media only screen and (max-width: 768px) {
            justify-content: space-around;
        }

        button {
            cursor: pointer;
            color: white;
            background-color: transparent;
            border: 1px solid #FBFBFB;
            border-radius: 7px;
            padding: 16px 30px;
            gap: 10px;
            font-size: 14px;
            font-weight: 600;
            @media only screen and (max-width: 1024px) {
                padding: 15px;
            }
        }
        button:nth-child(3) {
            background-color: #308DE2;
            border-color: #1E6AAF;
        }

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.5;

            span {
                font-size: 14px;
                color: #FBFBFB;
                font-weight: normal;
            }
        }
    }

    .zoom-controls {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        div {
            cursor: pointer;
            color: white;
            background-color: transparent;
            margin: 10px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1;
        }
    }

    .pdfViewerDocument {
        display: flex;
        
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
        .pdfViewerPage {
            overflow: auto;
            @media only screen and (max-width: 1024px) {
                canvas, div {
                    width: 100% !important;
                    height: auto !important;
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}