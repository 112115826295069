.error-page {
    height: auto;
    padding-top: 80px;
    background: linear-gradient(
        180deg,
        #000000 0%,
        rgba(0, 0, 0, 0.41) 7.45%,
        rgba(0, 0, 0, 0) 12.94%,
        rgba(0, 0, 0, 0) 72.35%,
        #000000 99.11%
        ), url("../../../images/sections/BGTexture.png");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: 10vw;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    h1 {
        margin: 1rem;
        text-transform: uppercase;
        font-size: 2rem;
    }

    div {
        text-align: center;
    }
}