.mint-body {
  background: linear-gradient(0deg, #000000 14.38%, rgba(0, 0, 0, 0) 59.48%),
    linear-gradient(0deg, rgba(21, 17, 40, 0.81) 0%, rgba(21, 17, 40, 0) 12.78%),
    linear-gradient(
      180deg,
      #000000 0%,
      rgba(0, 0, 0, 0.54) 9.75%,
      rgba(0, 0, 0, 0.33) 20.32%,
      rgba(0, 0, 0, 0) 34.58%
    ),
    url("../../../images/BGTexture.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}

.mint-body .mint-body-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mint-body .mint-body-inner img.mint-logo {
  width: 100% !important;
}

.mint-body .mint-body-inner .nft-container {
  width: 100%;
  background: linear-gradient(
      133.83deg,
      rgba(255, 128, 219, 0.13) 1.7%,
      rgba(82, 106, 192, 0.13) 53.92%,
      rgba(82, 229, 239, 0.13) 109.64%
    ),
    rgba(255, 255, 255, 0.05);
  box-shadow: 9px 19px 33px rgba(0, 0, 0, 0.65), 3px 3px 1px rgba(0, 0, 0, 0.5),
    inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.mint-body .mint-body-inner .nft-container .nft-image {
  width: 100%;
  margin-top: -64px;
  padding-bottom: 32px;
}

.mint-body .mint-body-inner .nft-container .nft-image img {
  width: 100%;
  filter: drop-shadow(0px 4px 74px #000000);
}

.mint-body .mint-body-inner .nft-container .nft-content {
  width: 100%;
  background: linear-gradient(
      131.47deg,
      rgba(3, 3, 3, 0.13) 1.2%,
      rgba(21, 28, 52, 0.13) 49.77%,
      rgba(95, 194, 201, 0.13) 101.59%
    ),
    rgba(255, 255, 255, 0.05);
  mix-blend-mode: normal;
  box-shadow: 9px 19px 33px rgba(0, 0, 0, 0.65), 3px 3px 1px rgba(0, 0, 0, 0.5),
    inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.mint-body .mint-body-inner .nft-container .nft-content h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 125.5%;
  /* or 30px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

.mint-body .mint-body-inner .nft-container .nft-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125.5%;
  /* or 25px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #9e9e9e;
}

.mint-body .mint-body-inner .mint-button {
  width: 100% !important;
}

.mint-body .mint-body-inner .mint-button button {
  width: 100% !important;
  max-width: 100%;
  height: auto;
  padding: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #ffffff;
}
.mint-body .mint-body-inner .mint-button button .visually-hidden {
  display: none;
}

.mint-description .mint-description-inner.description {
  background-color: #261937;
  padding: 60px 12%;
}

.mint-description .mint-description-inner.description > div {
  max-width: 1080px;
  margin: 0 auto;
}

@media only screen and (max-width: 786px) {
  .mint-description .mint-description-inner.description {
    padding: 60px 6%;
  }
}
