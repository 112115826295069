.videosection {
    position: relative;
    padding-bottom: 56.25%;
    margin-top: 90px;
}

#video-armorite {
    position: absolute;
    top: 0px;
    right: -100px;
    height: 25vh;
}

.videosection .videosection-inner {
    position: absolute;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 600px) {
    #video-armorite {
        right: -230px;
        height: initial;
    }
}
