.testimonial {
  margin-top: -100px;
  background: linear-gradient(
      180deg,
      #000000 0%,
      rgba(0, 0, 0, 0.41) 7.45%,
      rgba(0, 0, 0, 0) 12.94%,
      rgba(0, 0, 0, 0) 72.35%,
      #000000 99.11%
    ),
    url("../../../images/sections/BGTexture.png");
  background-repeat: no-repeat;
  background-size: cover;
  .tiles-item-inner {
    background: get-color(dark, 2);
  }
}
.testimonial .testimonial-middle {
  width: 100%;
  height: 100%;
  background: url("../../../images/sections/dynasties/lineback.png");
  background-repeat: no-repeat;
  background-size: 100%;
  // background-position: center;
}

.testimonial-item-content {
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 18px;
    margin-top: 12px;
    margin-bottom: 16px;
    background-image: inline-svg(
      '<svg width="24" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M0 13.481c0-2.34.611-4.761 1.833-7.263C3.056 3.716 4.733 1.643 6.865 0L11 2.689C9.726 4.382 8.777 6.093 8.152 7.824c-.624 1.73-.936 3.578-.936 5.545V18H0v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263 1.223-2.502 2.9-4.575 5.032-6.218L24 2.689c-1.274 1.693-2.223 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18H13v-4.519z" fill="' +
        get-color(primary, 1) + '" fill-rule="nonzero"/></svg>'
    );
    background-repeat: no-repeat;
  }
}

.testimonial-item-footer {
  font-weight: 600;
  padding-top: 20px;

  &.has-top-divider {
    &::before {
      background: get-color(dark, 3);
    }
  }
}

.testimonial-item-link {
  color: get-color(secondary, 3);

  a {
    color: get-color(secondary, 3);
    text-decoration: none;

    &:hover {
      color: get-color(secondary, 2);
    }
  }
}

.testimonial .section-header h3 {
  font-size: 48px;
  line-height: 93.5%;
  font-weight: 600;
}

.testimonial .testimonial-inner {
  padding-top: 50px;
}

.testimonial .testimonial-inner .armorite {
  position: absolute;
}

#armorite11 {
  top: 318px;
  right: 0px;
  z-index: 500;
}

#armorite12 {
  top: 418px;
  right: 114px;
  z-index: 500;
}

#armorite13 {
  top: 600px;
  right: 200px;
  z-index: 500;
}

#armorite11, #armorite12, #armorite13 {
  display: none;
  pointer-events: none;
}

@media screen and (min-width: 992px) {
  #armorite11, #armorite12, #armorite13 {
    display: initial;
  }

}