.dynasty-comp {
  cursor: pointer;
  position: relative;
  z-index: 50;
  margin-left: -4vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynasties-container {
  margin-right: -4.5vw;
}

.dynasty-img {
  position: absolute;
}

.dynasty-overlay {
  position: absolute;
  opacity: 0.6;
  pointer-events: none;
}

.dynasty-text {
  pointer-events: none;
  visibility: visible;
  text-align: center;
  opacity: 1;
  transition: visibility 0s, opacity 1s linear;
  z-index: 100;
  position: absolute;
  left: 0%;
  bottom: 10%;
  padding-top: 5rem;
  padding-left: 3.6rem;
  padding-right: 3.6rem;
  height: fit-content;
  margin: 0 auto;
  top: 48%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  @media screen and (max-width: 600px) {
    transform: unset;
    top: 0;
  }  

  .dynasty-title {
    font-size: 2rem;
    font-weight: 600;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: .1rem;
    line-height: 29px;
  }
  .dynasty-subtitle {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .dynasty-content {
    font-size: 1rem;
    line-height: 0.9rem;
    font-weight: 500;
  }
}

.dynasty-comp:hover {
  z-index: 99 !important;
  display:flex;
  align-items: center;
  justify-content: center;
  .dynasty-text {
    transition-delay: 500ms;
    padding-top: 0;
    visibility: visible;
    opacity: 1;
  }  
}

.dynasty-comp img {
  width: 20vw;
  height: 100%;
  transition: transform 1s;
  top: 0;
}

@media screen and (min-width: 600px) {
  .dynasty-comp:nth-child(1) .dynasty-text {
    left: 0%;
  }
  .dynasty-overlay {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  .dynasty-text {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    left: 0%;
    padding-top: 0;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    .dynasty-title {
      font-size: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      line-height: 0.5rem;
    }
    .dynasty-subtitle {
      font-size: 0.6rem;
      margin-bottom: 0;
    }
    .dynasty-content {
      font-size: 0.55rem;
      line-height: 0.55rem;
    }

  }

  .dynasty-img-container:hover {
    .dynasty-img {
      transform: rotateY(180deg) scale(1.4, 1.2);
      transition: transform 1s;
    }
    .dynasty-overlay {
      opacity: 0.6;
      transform: rotateY(180deg) scale(1.4, 1.2);
      transition: transform 1s;
    }
  }
}

@media screen and (min-width: 900px) {
  .dynasty-text {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    left: 0%;
    padding-left: 2vw;
    padding-right: 2vw;

    .dynasty-title {
      font-size: 1.2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      text-transform: uppercase;
      margin-bottom: .1rem;
      line-height: 1.1rem;
    }
    .dynasty-subtitle {
      font-size: 0.8rem;
      margin-bottom: 1rem;
    }
    .dynasty-content {
      line-height: 0.9rem;
    }
  }
}