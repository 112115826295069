.site-header {
  z-index: 9999 !important;
  transition: all 2s;
}

.fixed-site-header {
  position: fixed !important;
  top: 0;
  width: 100%;
  transition: all 2s;
}

.site-header-inner {
  // Created by Alberto Biel
  padding: 18.99px 31.13px;
  background: linear-gradient(90deg, #000000 12.89%, rgba(18, 16, 38, 0.21) 68.26%);
  border-bottom: 1px solid #4C375F;
}
