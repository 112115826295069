.privacy_policy-page {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .privacy_policy-top_section {
    padding: 122px 0 42px;
    background: linear-gradient(0deg, #000000 3.67%, rgba(0, 0, 0, 0) 54.42%),
      url("../../../images/sections/BGTexture.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  h1 {
    text-transform: uppercase;
    font-weight: 600;
    line-height: 93.5%;
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
    backdrop-filter: blur(12px);
  }

  .privacy_policy-main {
    padding: 22px 30px;
    background-color: #0f0b18;
    line-height: 31px;

    h2 {
      font-size: 0.9rem;
      font-weight: 700;
    }

    li {
      font-size: 0.8rem;
      margin-bottom: 0;
    }

    p {
      font-size: 0.8rem;
      font-weight: 400;
    }

    a {
      color: #308de2;
    }
  }

  .table-of-contents {
    font-weight: 700;

    ol {
      list-style-position: inside;
      padding: 0;
    }
  }

  .top-level-list {
    margin: 0;
    padding: 0;
    list-style-position: inside;
    font-weight: 700;
  }

  .nested-list {
    font-weight: 400;

    ol,
    ul {
      margin-top: 0;
      padding-left: 10px;
    }
  }

  .list-style-circle {
    list-style-type: circle;
  }

  .section-heading {
    h2 {
      color: #308de2;
      font-weight: 400;
      margin: 0;
      font-size: 1rem;
    }
  }

  table {
    text-transform: capitalize;

    th {
      border: 2px solid #d6d6d6;
      padding: 5px 0 5px 5px;
      font-weight: 400;
    }

    td {
      border: 2px solid #d6d6d6;
      padding: 8px 0 8px 8px;
      vertical-align: top;
      font-size: 0.6rem;
      font-weight: 400;
    }
  }

  @media screen and (min-width: 768px) {
    table {
      th {
        border: 2px solid;
        font-size: 1rem;
      }

      td {
        border: 2px solid;
        font-size: 0.8rem;
      }
    }
  }

  @media screen and (min-width: 968px) {
    .privacy_policy-top_section {
      padding-left: 100px;
      background: url("../../../images/sections/BGTexture.png");
      background-position: top;
      vertical-align: center;

      button {
        background-color: transparent;
        border: none;
        font-size: 0.8rem;
        font-weight: 500;

        &:before {
          padding-right: 12px;
          content: url("../../../../assets/images/back-chevron.svg");
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    h1 {
      font-size: 2rem;
      text-align: left;
      margin: 128px 0 60px;
      backdrop-filter: unset;
    }

    section {
      margin: auto;
      width: 890px;
    }

    .privacy_policy-main {
      h2 {
        font-size: 1.2rem;
      }

      li {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;
        width: 860px;
      }

      .section-heading {
        h2 {
          font-size: 1.5rem;
          margin-bottom: 16px;
        }
      }
    }

    table {
      th {
        padding: 18px 30px;
        font-size: 1rem;
      }

      td {
        padding: 31px 30px;
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    section {
      margin: 0;
      width: 1100px;
      padding-left: 167px;
    }

    p {
      width: 900px;
    }
  }
}
