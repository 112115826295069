.features-split-inner .section-header h3 {
  font-size: 48px;
  line-height: 93.5%;
  font-weight: 600;
}

.split-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.split-item:nth-of-type(odd) {
  flex-wrap: wrap-reverse;
}

.split-item-content {
  flex: 60%;
  background: linear-gradient(
      118.92deg,
      rgba(237, 90, 143, 0.1) 1.07%,
      rgba(82, 126, 239, 0.1) 99.61%
    ),
    rgba(255, 255, 255, 0.05);
  box-shadow: 9px 7px 60px #0c1516, 9px 19px 33px rgba(0, 0, 0, 0.65),
    3px 3px 1px rgba(0, 0, 0, 0.5), inset 1px 1px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 10px;

  padding: 1em 2em;

  @media all and (max-width: 600px){
    flex: unset;
  }
}

.split-item-content h4 {
  font-size: 20px;
  line-height: 18px;
}

.split-item-content h5 {
  font-size: 16px;
  line-height: 18px;
}

.split-item-content p {
  font-size: 14px;
  line-height: 22px;
}
.split-item-image {
  padding-bottom: 1rem;
  backdrop-filter: blur(26.0534px);
}
.split-item-divider {
  visibility: hidden;
  width: 50px;
  border-top: 1.7369px solid #56564b;
}
.split-item img {
  border-radius: 50%;
  //border: 12px solid #050503;
  border: 1px solid #56564b;
  //outline: 1.7369px solid #56564b;
  padding: 10px;
  width: 140px;
  height: 140px;
}

.feature-split .section-header h3 {
  font-size: 48px;
  line-height: 93.5%;
  font-weight: 600;
}

.features-split-middle {
  position: relative !important
  ;
}

#armorite31  {
  position: absolute;
  top: 1000px;
  left: 0px;
  z-index: 500;
  height: 20vh
}

@media screen and (min-width: 600px){
  .split-item-divider {
    visibility: visible;
  }
  .split-item-image {
    padding-bottom: 0;
  }

  #armorite31  {
    top: 233px;
    height: initial;
  }
}